/* eslint-disable @typescript-eslint/no-var-requires */
import * as React from 'react'
import styled from 'styled-components'
import { graphql, Link, PageProps } from 'gatsby'

import MainLayout from 'components/layouts/Main'
import Container from 'components/common/Container'
import CaretRight from 'components/vectors/CaretRight'

import appContent from 'content/app.json'

import InspectionQuery from 'models/InspectionQuery'

import colors from 'lib/utils/colors'
import withAuth from 'lib/utils/withAuth'

const ContentContainer = styled(Container)`
  text-align: left;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-top: 64px;
`

const ListItemContainer = styled(Container)`
  background-color: ${colors.White};
  border-radius: 24px;
  width: 170px;
  height: 170px;
  padding: 15px;
  padding-bottom: 20px;
`

const ListItemContentContainer = styled(Container)`
  flex-direction: column;
  justify-content: space-between;
`

const ListIconContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const ListItemTextContainer = styled(Container)`
  flex-direction: row;
`

const Label = styled.h5`
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: ${colors.Black};
  white-space: pre-wrap;
  margin-bottom: 0;
  align-self: flex-end;
`

const IconContainer = styled(Container)`
  margin: auto;
`

type InspectionPageProps = PageProps & { data: { inspections: InspectionQuery } }

const InspectionPage: React.FC<InspectionPageProps> = ({ location, data }) => {
  return (
    <MainLayout
      backgroundColor={appContent.backgroundColor}
      backgroundImage={appContent.backgroundImage}
      showTabBar={!!appContent.tabBar}
      currentPath={location.pathname}
      pageTitle={data.inspections.edges[0].node.frontmatter.defaultPageTitleText}
      content={
        <ContentContainer>
          {data.inspections.edges.map((item) => {
            const {
              frontmatter: { title, slug, icon },
            } = item.node

            return (
              <Link
                key={item.node.id}
                style={{
                  textDecoration: 'none',
                  marginBottom: '10px',
                  marginLeft: '5px',
                  marginRight: '5px',
                }}
                to={slug}
              >
                <ListItemContainer>
                  <ListItemContentContainer>
                    <ListIconContainer>
                      {React.createElement(require(`assets/icons/${icon}`))}
                    </ListIconContainer>
                    <ListItemTextContainer>
                      <Label>{title}</Label>
                      <IconContainer>
                        <CaretRight />
                      </IconContainer>
                    </ListItemTextContainer>
                  </ListItemContentContainer>
                </ListItemContainer>
              </Link>
            )
          })}
        </ContentContainer>
      }
    />
  )
}

export const query = graphql`
  {
    inspections: allMdx(
      filter: { frontmatter: { group: { eq: "inspection" } } }
      sort: { fields: frontmatter___index }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            defaultPageTitleText
            icon
          }
        }
      }
    }
  }
`

export default withAuth(InspectionPage)
